var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoiceRespVos.length > 0
    ? _c(
        "div",
        { staticClass: "tasting" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("发票信息")]),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                border: "",
                "show-overflow": "",
                data: _vm.invoiceRespVos,
              },
              on: {
                "checkbox-change": _vm.checkboxChange,
                "checkbox-all": _vm.checkboxAll,
              },
            },
            [
              _vm._l(_vm.columns, function (config) {
                return [
                  _c(
                    "vxe-table-column",
                    _vm._b(
                      {
                        key: config.id,
                        attrs: { "min-width": config.minWidth || 150 },
                      },
                      "vxe-table-column",
                      config,
                      false
                    )
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }