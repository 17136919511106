<script>
import request from '@/found/utils/request';
import Storage from '@/found/utils/storage';
import Form, { formCreate } from '@/found/components/form';
import TableSelect from '../components/tableSelect.vue';
import InvoiceList from './invoice_list.vue';

formCreate.component('TableSelect', TableSelect);
formCreate.component('InvoiceList', InvoiceList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230307000000004',
      formFunctionCode: 'dms_award_application_form',
      buttonText: {
        submit: '保存',
      },
      buttons: {
        submitAudit: this.formConfig.code !== 'view',
      },
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'companyName') {
        const paramsObj = {
          functionCode: 'company_body_table',
          data: [],
          selectionList: [],
          idKey: 'id',
          noReset: true,
          paramData: { enableStatus: '009' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '公司主体',
        };
      }
      if (item.field === 'dealerName') {
        const paramsObj = {
          functionCode: 'dealer_table',
          data: [],
          selectionList: [],
          idKey: 'dealerCode',
          noReset: true,
          paramData: { enableStatus: '009', actApproveStatus: '3' },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '受益经销商',
        };
      }
      if (item.field === 'applyAmount') {
        item.props = {
          ...item.props,
          min: 0,
          precision: 2,
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      this.disabled(true, ['year']);
      if (this.formConfig.code === 'add') {
        this.disabled(true, ['dealerName']);
      }
      const company = this.getRule('companyName');
      const dealer = this.getRule('dealerName');
      if (this.formConfig.code === 'add' || this.formConfig.code === 'edit') {
        company.on.change = (val) => {
          this.setValue({
            companyCode: val.companyCode,
            companyName: val.companyName,
            year: val.year,
            companyBudgetCode: val.companyAwardCode,
            ext1: val.ext1,
          });
          dealer.props = {
            ...dealer.props,
            paramsObj: {
              functionCode: 'dealer_table',
              data: [],
              selectionList: [],
              idKey: 'dealerCode',
              noReset: true,
              paramData: { enableStatus: '009', actApproveStatus: '3' },
            },
          };
          this.disabled(false, 'dealerName');
        };
        dealer.on.change = (val) => {
          this.setValue({
            dealerCode: val.customerCode,
            dealerName: val.customerName,
          });
        };
      }
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .post('/dms/dmsawarddealerbudget/query', {
            id: this.formConfig.row ? this.formConfig.row.id : this.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              dealer.props = {
                ...dealer.props,
                paramsObj: {
                  functionCode: 'dealer_table',
                  data: [],
                  selectionList: [],
                  idKey: 'dealerCode',
                  noReset: true,
                  paramData: { enableStatus: '009', actApproveStatus: '3' },
                },
              };
              data.attachmentList = data.attachmentList ? data.attachmentList : [];
              this.getRule('invoiceRespVos').props.invoiceRespVos = res.result.invoiceRespVos ? res.result.invoiceRespVos : [];
              this.setValue(data);
            }
          });
      }
    },

    // 表单提交
    submit(type) {
      const formData = this.getFormData();
      if (formData) {
        let url = '/dms/dmsawarddealerbudget/saveUpdate';
        const params = { ...formData, id: this.formConfig.row.id || '', dealerBudgetCode: this.formConfig.row.dealerBudgetCode || '' };
        if (this.formConfig.code === 'edit') {
          url = '/dms/dmsawarddealerbudget/saveUpdate';
          if (type === 1) {
            params.saveType = 3;
          } else {
            params.saveType = 4;
          }
        } else {
          params.saveType = type;
        }
        if (type === 2) {
          this.$emit('submit', {
            row: params,
            submitUrl: url,
          });
        } else {
          request.post(url, params).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '操作成功',
              });
              this.$emit('onClose');
              this.$emit('onGetList');
            }
          });
        }
      }
    },
  },
};
</script>
