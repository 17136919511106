<template>
  <div v-if="invoiceRespVos.length>0" class="tasting">
    <div class="title">发票信息</div>
      <vxe-table
        border
        show-overflow
        ref="xTable"
        :data="invoiceRespVos"
        @checkbox-change="checkboxChange"
        @checkbox-all="checkboxAll"
      >
        <template v-for="config in columns">
          <vxe-table-column
            :key="config.id"
            v-bind="config"
            :min-width="config.minWidth || 150"
          ></vxe-table-column>
        </template>
      </vxe-table>
  </div>
</template>
<script>
import _ from 'xe-utils';
import request from '@/found/utils/request';

export default {
  name: 'edit_details',
  props: {
    invoiceRespVos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [],
    };
  },
  components: {},
  watch: {
    invoiceRespVos() {
      if (this.invoiceRespVos.length > 0) {
        this.getColumns();
      }
    },
  },
  created() {

  },
  methods: {
    // 获取发票表头配置
    async getColumns() {
      const params = {
        parentCode: 'CRM20230307000000004',
        functionCode: 'invoice_list',
      };
      const res = await request.post('/mdm/mdmtableconfig/query', params);
      if (res.success) {
        this.columns = res.result.column
          .filter((v) => v.visible)
          .map((v) => {
            const rowData = v;
            return rowData;
          });
      }
    },
  },
};
</script>

<style lang="less" scepod>
.red {
  color: #f56c6c !important;
  margin-left: 10px;
}
.tasting{
  margin-top: 20px;
}
.title{
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 18px;
}
</style>
